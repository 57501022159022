<template>
    <div ref="document" class="popup_scan">
     
        
        <div v-if="displaySecond" id="container2">
        
        </div>
        <div v-if="displayFirst" id="container">
        
        </div>
        

        <img v-if="photoData" :src="photoData" />
        <img v-if="photoData2" :src="photoData2" />
        
    </div>
    

  </template>
  
  
  <script>
  import jQuery from 'jquery';
  import {APIRequest} from "@simb2s/senseye-sdk";

  import * as THREE from 'three';
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
  
  var camera, controls, scene, renderer, center;
  
  export default {
    components: {  },
    name: 'ScanCanvas',
    props:['scan','view'],
    data() {
      return {
        scanDetail:null,
        center:null,
        camera:null,
        camera2:null,
        photoData2:null,
        controls:null,
        displayFirst:true,
        displaySecond:true,
         controls2:null,
        scene:null, 
        scene2:null, 
        renderer:null,
        renderer2:null,
        container:null,
        container2:null,
        geometry:null,
        geometry2:null,
        photoData:null,
        ptcloud_array:[]
      }
    },
    async mounted(){
  
      await this.getScanDetails()
  
  
      var pointCloud = this.scanDetail.coordinates;
  
      this.ptcloud_array = pointCloud.split(/\r?\n/);

      
        this.container = document.getElementById("container2");
     
        this.container2 = document.getElementById("container");
      
   
      
      this.init()
     
    },
    beforeUnmount() {
          window.removeEventListener('resize', this.onWindowResize);
          window.removeEventListener('resize', this.onWindowResize2);
    },
    methods:{
      closeModal($event)
          {
              
              if(jQuery($event.target).parents('.conent').length)
              {
                
              }
              else
              {
                emitter.emit('hide-popup-scan')
                  $event.preventDefault()
              }
              
          },
     
      async getScanDetails() {
        this.scanDetail=this.scan
     
      },
      init2() {

        renderer = new THREE.WebGLRenderer( { antialias: true } );
          
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( container.offsetWidth, container.offsetHeight );
        container2.appendChild( renderer.domElement );
        scene = new THREE.Scene();
        scene.background = new THREE.Color( 0xf8f8f8 );
        camera = new THREE.PerspectiveCamera( 15, container.offsetWidth / container.offsetHeight, 0.01, 40 );

    
            camera.position.x = -1.5; // x and z determines face direction
            camera.position.z = 0; // - 2
       
        


        camera.position.y = 0; // y determines angle from above
        scene.add( camera );
  
        controls = new OrbitControls( camera, renderer.domElement );
  
        var geometry = new THREE.BufferGeometry();
        var points_array=[]
        var colors_array=[]
        
        for(let i=0;i<this.ptcloud_array.length;i++) {
        
          var points_ex=this.ptcloud_array[i].split(',')
          points_array.push(points_ex[0])
          points_array.push(points_ex[1])
          points_array.push(points_ex[2])
          
          colors_array.push(points_ex[3],points_ex[4],points_ex[5])
          
        }
        
        
        var points_vertices = new Float32Array( points_array);
        
        var colors = new Uint8Array( colors_array);
      
        
        geometry.setAttribute( 'position', new THREE.BufferAttribute( points_vertices, 3 ) );
        geometry.setAttribute( 'color', new THREE.BufferAttribute( colors, 3 ) );
        
        geometry.attributes.color.normalized = true;
          
        
        var material = new THREE.PointsMaterial({vertexColors: true, size: 0.0045 })
        
        var mesh = new THREE.Points(geometry, material );
  
        mesh.name = "headp.ply";
        scene.add( mesh );
  
        if(mesh.geometry.boundingSphere!=null) {
           center = mesh.geometry.boundingSphere.center;
      
          camera.lookAt(center);
          center.x -= 0.03;
          center.y += 0;
          
       
        }
          
        mesh.rotation.y+=1.6
        
        this.animate2()
  
        window.addEventListener( 'resize', this.onWindowResize2, false );

        
      },
      init() {
        renderer = new THREE.WebGLRenderer( { antialias: true } );
          
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( container.offsetWidth, container.offsetHeight );
        container.appendChild( renderer.domElement );
        scene = new THREE.Scene();
        scene.background = new THREE.Color( 0xf8f8f8 );
        camera = new THREE.PerspectiveCamera( 15, container.offsetWidth / container.offsetHeight, 0.01, 40 );

        if(this.view=='front')
        {
            camera.position.x = 1.5; // x and z determines face direction
            camera.position.z = 0; // - 2
        }
        else
        {
            camera.position.x = 0; // x and z determines face direction
            camera.position.z = 1.5; // - 2
        }
        


        camera.position.y = 0; // y determines angle from above
        scene.add( camera );
  
        controls = new OrbitControls( camera, renderer.domElement );
  
        var geometry = new THREE.BufferGeometry();
        var points_array=[]
        var colors_array=[]
        
        for(let i=0;i<this.ptcloud_array.length;i++) {
        
          var points_ex=this.ptcloud_array[i].split(',')
          points_array.push(points_ex[0])
          points_array.push(points_ex[1])
          points_array.push(points_ex[2])
          
          colors_array.push(points_ex[3],points_ex[4],points_ex[5])
          
        }
        
        
        var points_vertices = new Float32Array( points_array);
        
        var colors = new Uint8Array( colors_array);
      
        
        geometry.setAttribute( 'position', new THREE.BufferAttribute( points_vertices, 3 ) );
        geometry.setAttribute( 'color', new THREE.BufferAttribute( colors, 3 ) );
        
        geometry.attributes.color.normalized = true;
          
        
        var material = new THREE.PointsMaterial({vertexColors: true, size: 0.0045 })
        
        var mesh = new THREE.Points(geometry, material );
  
        mesh.name = "headp.ply";
        scene.add( mesh );
  
        if(mesh.geometry.boundingSphere!=null) {
           center = mesh.geometry.boundingSphere.center;
      
          camera.lookAt(center);
          center.x -= 0.03;
          center.y += 0;
          
       
        }
          
        mesh.rotation.y+=1.6
        
        this.animate()
  
        window.addEventListener( 'resize', this.onWindowResize, false );
      },
      onWindowResize() {
        camera.aspect = container.offsetWidth / container.offsetHeight;
        camera.updateProjectionMatrix();
       renderer.setSize( container.offsetWidth, container.offsetHeight );
        controls.handleResize();
      },
      onWindowResize2() {
        camera2.aspect = container.offsetWidth / container.offsetHeight;
        camera2.updateProjectionMatrix();
       renderer2.setSize( container.offsetWidth, container.offsetHeight );
        controls2.handleResize();
      },
      animate2() {
        requestAnimationFrame( this.animate2 );
        renderer.render( scene, camera);
       
            if(!this.photoData)
            {

                var cav = document.querySelector('#container2 canvas');

                const base64 = cav.toDataURL('img/png');
                this.photoData=base64
               
                this.displaySecond=false
            

            }
        },
        destroyFirst()
        {
            this.displayFirst=false
            this.init2()
        },
      
      animate() {
        requestAnimationFrame( this.animate );
        renderer.render( scene, camera );
       
            if(!this.photoData2)
            {

               
                var cav = document.querySelector('#container canvas');
                
                
                
                var base64 = cav.toDataURL('img/png');
                this.photoData2=base64
        
                this.destroyFirst()
            

               
            }
        
        
        
      }
      
    },
  
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    #container {
      background:grey;
        display:inline-block;
        
      width:50%;
      height:45vw;

      max-height: 410px;
    }
    #container2 {
      background:grey;
        display:inline-block;
      width:50%;
      height:35vw;
      max-height: 410px;
    }
    .popup_scan img 
    {
      width:50%;
    }
    .popup_scan {
      position:relative;
      top:0;
      left:0;
      width:100%;
      
      height:100%;
        
     display:flex;
    }
  </style>
  